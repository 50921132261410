if ($('.trainees-grid').length) {
  var Isotope = require('isotope-layout');

  var iso = new Isotope( '.trainees-grid', {
      itemSelector: '.trainee',
      layoutMode: 'vertical',
      transitionDuration: 0,
  });

  // bind filter button click
  $('.filters-button-group').on( 'click', 'button', function() {
    var filterValue = $( this ).attr('data-filter');
    iso.arrange({ filter: filterValue})
     var countItemElems = iso.getFilteredItemElements().length;
     $('#count-trainees').text(countItemElems);
  });
}
